import React, { useState, useEffect } from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Box,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { default as tuge_api } from "../apis/tuge";
import Alert from "@material-ui/lab/Alert";
import Loader from "./Loader";
import TableHeadCell from "./TableHeadCell";

const DeviceActionHistory = (props) => {
  const [deviceData, setDeviceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { onClose, open, deviceId: device_id } = props;

  useEffect(() => {
    if (device_id !== null) {
      setLoading(true);
      setDeviceData({});
      tuge_api.get(`/device/${device_id}/history`).then(({ data }) => {
        console.log(data);
        setDeviceData(data);
        setLoading(false);
      });
    }
  }, [device_id]);
  if (loading) {
    return <Loader />;
  } else {
    return (
      <React.Fragment>
        <Dialog
          onClose={onClose}
          open={open}
          aria-labelledby="simple-dialog-title"
          fullWidth={false}
          maxWidth={"lg"}
        >
          <DialogTitle id="simple-dialog-title">
            {device_id} Action History
          </DialogTitle>
          <DialogContent>
            {deviceData && (
              <React.Fragment>
                {deviceData.msg && (
                  <Box pt={3}>
                    <Alert severity="error"> {deviceData.msg} </Alert>
                  </Box>
                )}
                {!deviceData.msg && (
                  <TableContainer component={Paper}>
                    <Table size="small" style={{ tableLayout: "fixed" }}>
                      <TableHead>
                        <TableRow>
                          <TableHeadCell>Action</TableHeadCell>
                          <TableHeadCell>Plan</TableHeadCell>
                          <TableHeadCell>Timestamp</TableHeadCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>

                        {deviceData.length!==0 && deviceData.map((item) => {
                          return (
                            <TableRow>
                              <TableCell>{item.action} </TableCell>
                              <TableCell>
                                {item.plan_name && item.plan_name}
                              </TableCell>
                              <TableCell>{item.created_at}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </React.Fragment>
            )}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
};

export default DeviceActionHistory;
