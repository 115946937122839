import React, { useState, useEffect, useRef } from "react";
import Loader from "../components/Loader";

import {
  Box,
  Typography,
  Paper,
  Container,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Button,
} from "@material-ui/core";
import TableCell from "./TableCell";
import GetAppIcon from "@material-ui/icons/GetApp";
import moment from "moment";
import { useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import TableHeadCell from "../components/TableHeadCell";
import { default as tuge_api } from "../apis/tuge";
export default function ClientDevices() {
  const exportDataLink = useRef();
  const { client_name, client_id } = useParams();

  const [loading, setLoading] = useState(false);

  const [devices, setDevices] = useState([]);
  const [exportDevicesData, setExportDevicesData] = useState([]);
  useEffect(() => {
    getDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDevices = () => {
    setLoading(true);
    tuge_api.get(`/client/${client_id}/devices`).then((response) => {
      setDevices(response.data);
      let exportData = [["Serial No."]];
      for (let device of response.data) {
        exportData.push([device]);
      }
      setExportDevicesData(exportData);
      setLoading(false);
    });
  };

  return (
    <React.Fragment>
      {loading && <Loader />}
      <Container fixed>
        <Box pt={2} pb={3}>
          <Typography variant="h4" align="left">
            Devices for {client_name}{" "}
          </Typography>
        </Box>

        {devices && devices.length === 0 && (
          <Box pt={2} pb={3}>
            <Typography variant="h5" align="center">
              No Devices.
            </Typography>
          </Box>
        )}

        <Box pt={2} pb={3}>
          {devices && devices.length !== 0 && (
            <React.Fragment>
              <Box pb={2}>
                <Grid container justifyContent="space-between" spacing={2}>
                  <Grid item>
                    {" "}
                    <Typography variant="h5" align="left">
                      {" "}
                      Total Devices : {devices && devices.length}{" "}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<GetAppIcon />}
                      onClick={() => {
                        exportDataLink.current.link.click();
                      }}
                    >
                      Export
                    </Button>
                    <CSVLink
                      data={exportDevicesData}
                      filename={
                        devices &&
                        `Devices_${client_name}_${moment().format(
                          "YYYYMMDD_hmmss"
                        )}.csv`
                      }
                      ref={exportDataLink}
                    />
                  </Grid>
                </Grid>
              </Box>

              <TableContainer component={Paper}>
                <Table size="small" style={{ tableLayout: "fixed" }}>
                  <TableHead>
                    <TableRow>
                      <TableHeadCell>Serial Number</TableHeadCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {devices.map((item) => {
                      return (
                        <TableRow key={item}>
                          <TableCell>{item}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          )}
        </Box>
      </Container>
    </React.Fragment>
  );
}
