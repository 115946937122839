import React, { useState, useEffect } from "react";
import Loader from "./Loader";
import {
    Box,
    Typography,
    Paper,
    Container,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    TextField,
    Button
} from "@material-ui/core";
import TableCell from "./TableCell";
import SendIcon from "@material-ui/icons/Send";

import { useParams } from "react-router-dom";

import TableHeadCell from "./TableHeadCell";
import Alert from "@material-ui/lab/Alert";
import { default as tuge_api } from "../apis/tuge";

export default function AssignDevices() {
    const { client_name, client_id } = useParams();

    const [loading, setLoading] = useState(false);

    const [devices, setDevices] = useState([]);
    const [message, setMessage] = useState();
    const [assignDevices, setAssignDevices] = useState("");
    useEffect(() => {
        getDevices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDevices = () => {
        setLoading(true);
        tuge_api
          .get(
            `/client/${client_id}/devices`
          )
          .then((response) => {
            setDevices(response.data);
            setLoading(false);
          });
    };

    const assignDevicesToClient = () => {
        let data = {client_id,devices:assignDevices.trim() === "" ? [] : assignDevices.trim().split("\n")};
        console.log(data);
        setLoading(true);
        tuge_api.post("/devices/assign-client", data).then((response) => {
          setMessage(response.data);
          getDevices();
        }).finally(()=>{
             setLoading(false);
        })
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <Container fixed>
                <Box pt={2} pb={3}>
                    <Typography variant="h4" align="left">Assign Devices for {client_name} </Typography>
                </Box>

                {message && (
                    <Box pt={3} pb={3}>
                        <Alert severity={message.type}> {message.text} </Alert>
                    </Box>
                )}

                <Grid container direction="row" spacing={2} justify="space-between">
                    <Grid item xs={8} lg={8}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Devices to Assign"
                            multiline
                            rows={10}
                            variant="outlined"
                            fullWidth
                            value={assignDevices}
                            onChange={(e) => {
                                setAssignDevices(e.target.value);
                            }}

                        ></TextField>
                    </Grid>

                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={assignDevicesToClient}
                            startIcon={<SendIcon />}
                        >
                            Assign Devices
                        </Button>
                    </Grid>
                </Grid>

                <Box pt={2} pb={3}>
                    {devices && devices.length !== 0 && (
                        <React.Fragment>
                            <Typography variant="h5" align="left"> Total Devices : {devices && devices.length} </Typography>

                            <TableContainer component={Paper}>
                                <Table size="small" style={{ tableLayout: "fixed" }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableHeadCell>Serial Number</TableHeadCell>

                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {devices.map((item) => {
                                            return (
                                                <TableRow key={item}>
                                                    <TableCell>
                                                        {item}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </React.Fragment>
                    )}
                </Box>
            </Container>
        </React.Fragment >
    )
}