import React, { useState, useRef } from "react";
import "../styles/customer-journey.css";
import Loader from "../components/Loader";
import { Box, Container, Grid, Typography, Button } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import { DataGrid } from "@material-ui/data-grid";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import GetAppIcon from "@material-ui/icons/GetApp";
import { CSVLink } from "react-csv";
import { makeStyles } from "@material-ui/styles";
import { default as tuge_api } from "../apis/tuge";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDataGrid-columnHeader": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));

const Report = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [fromDate, setFromDate] = React.useState(
    new Date(moment().subtract(30, "days"))
  );
  const [toDate, setToDate] = React.useState(new Date());
  const [message, setMessage] = useState(null);
  const exportDataLink = useRef();

  const generateReport = () => {
    setLoading(true);
    tuge_api
      .get("/devices/action-report", {
        params: {
          from_date: moment(fromDate).format("yyyy-MM-DD"),
          to_date: moment(toDate).format("yyyy-MM-DD"),
        },
      })
      .then(({ data }) => {
        setReportData(data);
        setMessage(`Report Generated Successfully.`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    { field: "device_id", headerName: "Device Id", minWidth: 300, flex: 1 },
    { field: "action", headerName: "Action", minWidth: 300, flex: 1 },
    { field: "plan_name", headerName: "Plan", minWidth: 300, flex: 1 },
    {
      field: "created_at",
      headerName: "Action Taken At",
      minWidth: 300,
      flex: 1,
    },
  ];

  const export_headers = [
    { label: "Device Id", key: "device_id" },
    { label: "Action", key: "action" },
    { label: "PlanName", key: "plan_name" },
    { label: "Action Taken At", key: "created_at" },
  ];

  return (
    <React.Fragment>
      {loading && <Loader />}
      <Container fixed>
        <Box mb={2} pt={2}>
          <Typography variant="h5" align="left">
            {props.title}
          </Typography>
        </Box>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            spacing={4}
            alignItems="center"
          >
            <Grid item xs={12} md={4} lg={4}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline-from"
                label="From Date"
                value={fromDate}
                onChange={(date) => {
                  setFromDate(date);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline-from"
                label="To Date"
                value={toDate}
                onChange={(date) => {
                  setToDate(date);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={generateReport}
              >
                Generate Report
              </Button>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
        {reportData.length !== 0 && (
          <React.Fragment>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<GetAppIcon />}
                  onClick={() => {
                    exportDataLink.current.link.click();
                  }}
                >
                  Export
                </Button>
                <CSVLink
                  data={reportData}
                  headers={export_headers}
                  filename={
                    reportData &&
                    `spark_${moment().format("YYYYMMDD_hmmss")}.csv`
                  }
                  ref={exportDataLink}
                />
              </Grid>
            </Grid>
            <Box pt={2} className={classes.root}>
              <DataGrid
                rows={reportData}
                columns={columns}
                // onSelectionModelChange={(itm) => setSelectedRows(itm.selectionModel)}
                disableSelectionOnClick
                // selectionModel={selectionModel}
                autoHeight
                getRowId={(row) => row.uuid}
              />
            </Box>
          </React.Fragment>
        )}
      </Container>

      <Snackbar
        open={message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={() => {
          setMessage(undefined);
        }}
      >
        <Alert
          onClose={() => {
            setMessage(undefined);
          }}
          severity="success"
        >
          {message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

Report.defaultProps = {
  title: "Generate Report",
};

export default Report;
