import React from "react";
import { TableCell as TableBodyCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  cell: {
    wordWrap: "break-word",
  },
}));

const TableCell  = (props) => {
  const classes = useStyles();
  return (
    <TableBodyCell className={classes.cell} {...props}>
      {props.children}
    </TableBodyCell>
  );
};

export default TableCell;
