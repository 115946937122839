import React, { useState, useEffect } from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Box,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Loader from "../components/Loader";
import TableHeadCell from "../components/TableHeadCell";
import { default as tuge_api } from "../apis/tuge";

const DeviceConnectionStatusDialog = (props) => {
  const [deviceData, setDeviceData] = useState({});
  const [loading, setLoading] = useState(false);
  const { onClose, open, deviceId: device_id } = props;

  useEffect(() => {
    if (device_id !== null) {
      setLoading(true);
      setDeviceData({});
      tuge_api.get(`/device/${device_id}/status`).then(({ data }) => {
        console.log(data);
        setDeviceData(data);
        setLoading(false);
      });
    }
  }, [device_id]);
  if (loading) {
    return <Loader />;
  } else {
    return (
      <React.Fragment>
        <Dialog
          onClose={onClose}
          open={open}
          aria-labelledby="simple-dialog-title"
          fullWidth={false}
          maxWidth={"lg"}
        >
          <DialogTitle id="simple-dialog-title">
            {device_id} Connection Status
          </DialogTitle>
          <DialogContent>
            {deviceData && (
              <React.Fragment>
                {deviceData.msg && (
                  <Box pt={3}>
                    <Alert severity="error"> {deviceData.msg} </Alert>
                  </Box>
                )}
                {!deviceData.msg && (
                  <TableContainer component={Paper}>
                    <Table size="small" style={{ tableLayout: "fixed" }}>
                      <TableHead>
                        <TableRow>
                          <TableHeadCell>Attribute</TableHeadCell>
                          <TableHeadCell>Value</TableHeadCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <TableCell>Device Status</TableCell>
                          <TableCell>{deviceData.deviceStatus}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Hotspot Connected</TableCell>
                          <TableCell>{deviceData.hotspotDeviceCount}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Data Usage</TableCell>
                          <TableCell>{deviceData.dataUsage} MB</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Network Type</TableCell>
                          <TableCell>{deviceData.networkType}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Network Name</TableCell>
                          <TableCell>
                            {deviceData.plmn === "310410"
                              ? "AT&T"
                              : deviceData.plmn === "310260"
                              ? "T-Mobile"
                              : deviceData.plmn === "311480"
                              ? "Verizon Wireless"
                              : deviceData.plmn}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Network Speed</TableCell>
                          <TableCell>{deviceData.networkSpeed}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>MCC</TableCell>
                          <TableCell>
                            {deviceData.mcc && deviceData.mcc.split(",")[0]}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>RSSI</TableCell>
                          <TableCell>{deviceData.signal}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Battery Remaining</TableCell>
                          <TableCell>{deviceData.batteryRemaining}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Last Login Time (GMT) </TableCell>
                          <TableCell>{deviceData.lastloginTime}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell> Firmware Version </TableCell>
                          <TableCell>{deviceData?.aversionM1}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </React.Fragment>
            )}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
};

export default DeviceConnectionStatusDialog;
