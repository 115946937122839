import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { DataGrid } from "@material-ui/data-grid";
import GetAppIcon from "@material-ui/icons/GetApp";
import HistoryIcon from "@material-ui/icons/History";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import { CSVLink } from "react-csv";
import ChangePortGroup from "../components/iqsim/ChangePortGroup";
import Loader from "../components/Loader";

import Switch from '@material-ui/core/Switch';
import { default as iqsim_api } from "./../apis/iqsim";

const IQSimDevices = (props) => {

  const { user } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [deviceData, setDeviceData] = useState(null);
  const [error, setError] = useState(undefined);
  const [message, setMessage] = useState(undefined);
  const exportDataLink = useRef();
  const [deviceId, setDeviceId] = useState("");
  const [actionHistoryData, setActionHistoryData] = useState([]);
  const [actionHistoryDeviceId, setActionHistoryDeviceId] = useState(undefined);
  const [changePortGroupDeviceId,setChangePortGroupDeviceId] = useState(undefined);
  const getDevices = () => {
    setLoading(true);
    iqsim_api
      .get("/devices")
      .then(({ data }) => {
        console.log(data);
        setDeviceData(data);
      })
      .catch((error) => {
        // console.log(error.response.data);
        setError("Error Occurred, Please retry !");
      })
      .finally(() => {
        setLoading(false);
      });

  };


  const activateDevice = (device_id) => {
    console.log(device_id);
    setLoading(true);
    iqsim_api
      .get(
        `/device/${device_id}/activate`
      )
      .then(({ data }) => {
        console.log(data);
        if (data.success) {
          setMessage(`${device_id} Device activated successfully.`);
        }
        getDevices();
      })
      .catch((error) => {
        // console.log(error.response.data);
        setError("Error Occurred, Please retry !");
      })
      .finally(() => {
        // setLoading(false);
      });
  };



  const deActivateDevice = (device_id) => {
    console.log(device_id);
    setLoading(true);
    iqsim_api
      .get(
        `/device/${device_id}/deactivate`
      )
      .then(({ data }) => {
        console.log(data);
        if (data.success) {
          setMessage(`${device_id} Device De-Activated successfully.`);
        }
        getDevices();
      })
      .catch((error) => {
        // console.log(error.response.data);
        setError("Error Occurred, Please retry !");
      })
      .finally(() => {
        // setLoading(false);
      });

  };

  const searchDevice = () => {
    if (deviceId.trim() === "") {
      alert("Enter Device Id");
    } else {
      setLoading(true);
      iqsim_api
        .get(
          `/device/${deviceId}/info`
        )
        .then(({ data }) => {
          console.log(data);
          if (data.error) {
            setError(data.error);
          } else {
            setError(undefined);
            setDeviceData({ listDevice: [data] });
          }

          setLoading(false);
        });
    }
  };

  const clearSearch = () => {
    setDeviceId("");
    getDevices();
  };


  const getActionHistoryData = (d_id) =>{
    setLoading(true);
    iqsim_api
      .get(`/device/${d_id}/action-history`)
      .then(({ data }) => {
        console.log(data);

        setActionHistoryData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const enableVpn = (d_id) =>{
    setLoading(true);
    iqsim_api
      .get(`/device/${d_id}/enable-vpn`)
      .then(({ data }) => {
        console.log(data);
        if (data.success) {
          setMessage(`${d_id} Device VPN Enabled successfully.`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const disableVpn = (d_id) =>{
    setLoading(true);
    iqsim_api
      .get(`/device/${d_id}/disable-vpn`)
      .then(({ data }) => {
        console.log(data);
        if (data.success) {
          setMessage(`${d_id} Device VPN Disabled successfully.`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }


  const columns = [
    // { field: "serial", headerName: "Device Id", minWidth: 300, flex: 1 },
    {
      field: "name", headerName: "Serial No.", minWidth: 250, flex: 1, filterable: true,
      renderCell: (params) => {
        return <React.Fragment>
          <Typography >{params.row.name}</Typography>
          <Tooltip title="Action History">
            <IconButton
              onClick={() => {
                setActionHistoryDeviceId(params.row.name);
                getActionHistoryData(params.row.name);
              }}
              size="small"
            >
              <HistoryIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={params.row.is_vpn_enabled ? 'Disable VPN' : 'Enable VPN'}>
          <Switch
        checked={params.row.is_vpn_enabled}
        onChange={(e)=>{
          if(e.target.checked){
            enableVpn(params.row.name);
            params.row.is_vpn_enabled = true;
          }else{
            disableVpn(params.row.name);
            params.row.is_vpn_enabled = false;
          }
        }}
        name="checkedA"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
       
      />
      </Tooltip>
        </React.Fragment>
      }
    },
    { field: "status", headerName: "Status", minWidth: 50, flex: 1, filterable: true },
    { field: "pass_status", headerName: "Pass Status", minWidth: 50, flex: 1, filterable: true },
    { field: "version", headerName: "Version", minWidth: 50, flex: 1, filterable: true },
    { field: "useddata", headerName: "Used Data", minWidth: 50, flex: 1, filterable: true },
    { field: "portgroups", headerName: "Port Groups", minWidth: 50, flex: 1, filterable: true, 
      renderCell: (params) => {
        return <React.Fragment>
          <Typography >{params.row.portgroups}</Typography>
          <Tooltip title="Change Port Group">
            <IconButton
              onClick={() => {
                setChangePortGroupDeviceId(params.row.name);
              }}
              size="small"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      }
    },
    { field: "deviceplan", headerName: "Device Plan", minWidth: 50, flex: 1, filterable: true },
    {
      field: "action", headerName: "Action", minWidth: 50, flex: 1,
      renderCell: (params) => {
        return <React.Fragment>
          {params.row.deviceplan !== '300GB' && (
            <Box p={2}>
              <Button
                variant="contained"
                color="primary" onClick={(e) => { activateDevice(params.row.name) }}>Activate</Button>
            </Box>)}
          {params.row.deviceplan === '300GB' && (<Box p={2}>
            <Button
              variant="contained"
              color="secondary" onClick={(e) => { deActivateDevice(params.row.name) }}>De-Activate</Button>
          </Box>)}
        </React.Fragment>;
      }
    }
  ];

  const actionHistoryColumns = [
    { field: "id", headerName: "Id", minWidth: 50},
    { field: "action", headerName: "Action", minWidth: 300, filterable: true },
    { field: "created_at", headerName: "Action Taken At", minWidth: 300, flex: 1}
  ]


  const export_headers = [
    { label: "Device Id", key: "name" },
    { label: "Status", key: "status" },
    { label: "Pass Status", key: "pass_status" },
    { label: "Version", key: "version" },
    { label: "Used Data", key: "useddata" },
    { label: "Port Groups", key: "portgroups" },
    { label: "Device Plan", key: "deviceplan" }
  ];

  useEffect(() => {
    getDevices();

  }, [user]);

  return (
    <React.Fragment>
      {loading && <Loader />}
      <Container maxWidth>
        <Box mb={2} pt={2}>
          <Typography variant="h5" align="left">
            {props.title}
          </Typography>
        </Box>


        {error && (
          <Box pt={3}>
            <Alert severity="error"> {error} </Alert>
          </Box>
        )}


        {message && (
          <Box pt={3}>
            <Alert severity="success"> {message} </Alert>
          </Box>
        )}

        <Grid
          container
          direction="row"
          justify="flex-start"
          spacing={4}
          alignItems="center"
        >
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              id="device_id"
              label="Device ID"
              variant="outlined"
              fullWidth
              value={deviceId}
              onChange={(e) => setDeviceId(e.target.value)}
              size="small"
            />
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={searchDevice}>
              Search Device
            </Button>
          </Grid>

          <Grid item>
            <Button variant="outlined" color="primary" onClick={clearSearch}>
              Clear Search
            </Button>
          </Grid>
        </Grid>


        {deviceData && deviceData.listDevice.length !== 0 && (
          <React.Fragment>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<GetAppIcon />}
                  onClick={() => {
                    exportDataLink.current.link.click();
                  }}
                >
                  Export
                </Button>
                <CSVLink
                  data={deviceData.listDevice}
                  headers={export_headers}
                  filename={
                    deviceData &&
                    `spark_${moment().format(
                      "YYYYMMDD_hmmss"
                    )}.csv`
                  }
                  ref={exportDataLink}
                />
              </Grid>
            </Grid>
            <Box pt={2} >
              <DataGrid
                rows={deviceData.listDevice}
                columns={columns}
                // onSelectionModelChange={(itm) => setSelectedRows(itm.selectionModel)}
                disableSelectionOnClick
                // selectionModel={selectionModel}
                autoHeight
                getRowId={row => row.serial}

              />
            </Box>

          </React.Fragment>

        )}
      </Container>

      <Dialog
        onClose={(e)=>{
          setActionHistoryDeviceId(undefined);
          setActionHistoryData([]);
        }}
        open={actionHistoryDeviceId !== undefined}
        aria-labelledby="simple-dialog-title"
        fullWidth
        maxWidth={'md'}
      >
        <DialogTitle id="simple-dialog-title">
          {actionHistoryDeviceId} Action History
        </DialogTitle>
        <DialogContent>


          <Box pt={2} >
            {actionHistoryData.length === 0 && (
              <Box pt={3}>
                <Alert severity="info"> No Data. </Alert>
              </Box>
            )}

            {actionHistoryData.length !== 0 && (
            <DataGrid
              rows={actionHistoryData}
              columns={actionHistoryColumns}
              // onSelectionModelChange={(itm) => setSelectedRows(itm.selectionModel)}
              disableSelectionOnClick
              // selectionModel={selectionModel}
              autoHeight
              getRowId={row => row.id}

            />)}
          </Box>
        </DialogContent>
      </Dialog>
      
      <ChangePortGroup open={changePortGroupDeviceId !== undefined} onClose={()=>{setChangePortGroupDeviceId(undefined)}} deviceId={changePortGroupDeviceId} ></ChangePortGroup>
    </React.Fragment>
  );
};

IQSimDevices.defaultProps = {
  title: "Manage vSIM Devices",
};

export default IQSimDevices;
