import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  IconButton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { DataGrid } from "@material-ui/data-grid";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../components/Loader";
import { RefreshOutlined } from "@material-ui/icons";
import AssignSimCardToDevice from "../components/iqsim/AssignSimCardToDevice";
import { default as iqsim_api } from "./../apis/iqsim";
const IQSimCards = (props) => {
  const { user } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [simCardData, setSimCardData] = useState([]);
  const [error, setError] = useState(undefined);
  // eslint-disable-next-line
  const [message, setMessage] = useState(undefined);

  const getSimCards = () => {
    setLoading(true);
    iqsim_api
      .get("/sim-cards")
      .then(({ data }) => {
        console.log(data);
        setSimCardData(data.services);
      })
      .catch((error) => {
        // console.log(error.response.data);
        setError("Error Occurred, Please retry !");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    { field: "id", headerName: "Sim Card Id", flex: 1 },
    { field: "provider", headerName: "Provider", flex: 1 },
    { field: "iccid", headerName: "ICCID", flex: 1 },
    {
      field: "devnameport",
      headerName: "GSM Device (port)",

      flex: 1,
    },
    {
      field: "slidnum",
      headerName: "SLID Num",

      flex: 1,
    },
    {
      field: "comment",
      headerName: "Comment",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 50,
      flex: 1,
      renderCell: (params) => {
        return (
          <React.Fragment>
            {params.row.devnameport === "" && (
              <AssignSimCardToDevice sim_card_id={params.row.id} />
            )}
          </React.Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    getSimCards();
  }, [user]);

  return (
    <React.Fragment>
      {loading && <Loader />}
      <Container maxWidth>
        <Box mb={2} pt={2}>
          <Grid container alignItems="center">
            <Grid item>
              <Typography variant="h5" align="left">
                {props.title}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={getSimCards}>
                <RefreshOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        {error && (
          <Box pt={3}>
            <Alert severity="error"> {error} </Alert>
          </Box>
        )}

        {message && (
          <Box pt={3}>
            <Alert severity="success"> {message} </Alert>
          </Box>
        )}

        <Box pt={2}>
          <DataGrid
            rows={simCardData}
            columns={columns}
            // onSelectionModelChange={(itm) => setSelectedRows(itm.selectionModel)}
            disableSelectionOnClick
            // selectionModel={selectionModel}
            autoHeight
            getRowId={(row) => row.id}
          />
        </Box>
      </Container>
    </React.Fragment>
  );
};

IQSimCards.defaultProps = {
  title: "Manage vSIM Cards",
};

export default IQSimCards;
