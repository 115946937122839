import React from "react";
import { TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const TableHeadCell = (props) => {
  const classes = useStyles();
  return (
    <TableCell className={classes.head} {...props}>
      {props.children}
    </TableCell>
  );
};

export default TableHeadCell;
