import React, { useState, useEffect } from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Link,
  Grid,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Loader from "../components/Loader";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { default as tuge_api } from "../apis/tuge";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
const ChangePlan = (props) => {
  const classes = useStyles();
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const { onClose, open, deviceId: device_id, title, buttonTitle } = props;
  const [message, setMessage] = useState(undefined);
  const { user } = useAuth0();

  const changeDevicePlan = () => {
    console.log(device_id);
    console.log(selectedPlan);
    setLoading(true);
    tuge_api
      .post("activate-device", {
        device_id,
        plan_id: selectedPlan,
      })
      .then(({ data }) => {
        console.log(data);
        if (data.error) {
          setError(data.error);
        } else {
          setMessage("New Plan Activated.");
        }
      })
      .catch((error) => {
        console.log(error.response.data);
        setError(error.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchPlans = () => {
    setLoading(true);
    setMessage(undefined);
    tuge_api
      .get("/plans", {
        params: { client: user[window.location.origin + "/clients"].join(",") },
      })
      .then(({ data }) => {
        console.log(data);
        setPlans(data);
        if (props.selectedPlan) {
          setSelectedPlan(
            data.filter((p) => p.plan_name === props.selectedPlan)[0].plan_id
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    console.log(device_id);
    if (device_id && device_id !== null) {
      fetchPlans();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device_id]);
  if (loading) {
    return <Loader />;
  } else {
    return (
      <React.Fragment>
        <Dialog
          onClose={onClose}
          open={open}
          aria-labelledby="simple-dialog-title"
          fullWidth={false}
          maxWidth={"lg"}
        >
          <DialogTitle id="simple-dialog-title">
            {title} {device_id}
          </DialogTitle>
          <DialogContent>
            {error && (
              <Box pt={3}>
                <Alert severity="error"> {error} </Alert>
              </Box>
            )}

            {message && (
              <Box pt={3}>
                <Alert severity="success"> {message} </Alert>
              </Box>
            )}
            {plans && (
              <Box pt={3} pb={3}>
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="select-plan">Select Plan</InputLabel>
                      <Select
                        labelId="select-plan"
                        id="plan"
                        value={selectedPlan}
                        onChange={(e) => setSelectedPlan(e.target.value)}
                      >
                        {plans.map((plan, index) => (
                          <MenuItem value={plan.plan_id} key={index}>
                            {plan.plan_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      component={Link}
                      onClick={() => {
                        changeDevicePlan();
                      }}
                    >
                      {buttonTitle}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
};

export default ChangePlan;
