import React, { useState } from "react";
import {
  Button,
  TableRow,
  Link,
  Chip,
  IconButton,
  Tooltip,
  Grid,
} from "@material-ui/core";
import TableCell from "./TableCell";
import PhonelinkRingIcon from "@material-ui/icons/PhonelinkRing";
import EditIcon from "@material-ui/icons/Edit";
import CircularProgress from "@material-ui/core/CircularProgress";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import HistoryIcon from "@material-ui/icons/History";
import { default as tuge_api } from "../apis/tuge";
import { InView } from "react-intersection-observer";

const DeviceRow = (props) => {
  const {
    item,
    suspendDevice,
    setChangePlan,
    setActivatePlan,
    setShowConnectionStatusDeviceId,
    setShowActionHistory,
    switchTower,
  } = props;
  const [loading, setLoading] = useState(false);

  const getConnectionStatus = () => {
    if (
      item !== null &&
      item.deviceStatus === "ACTIVATED" &&
      item.status === undefined
    ) {
      setLoading(true);
      tuge_api
        .get(`/device/${item.deviceNO}/status`)
        .then(({ data }) => {
          item.status = data.deviceStatus;
          item.plmn = data.plmn;
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <InView
      as={TableRow}
      onChange={(inView, entry) => {
        if (inView || entry.isIntersecting) {
          getConnectionStatus();
        }
      }}
      key={Math.random()}
    >
      <TableCell>
        <Grid container direction="row" alignItems="center" spacing={1}>
          {item.deviceNO}
          <Grid item>
            <Tooltip title="Connection Status">
              <IconButton
                onClick={() => {
                  setShowConnectionStatusDeviceId(item.deviceNO);
                }}
                size="small"
              >
                <PhonelinkRingIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title="Action History">
              <IconButton
                onClick={() => {
                  setShowActionHistory(item.deviceNO);
                }}
                size="small"
              >
                <HistoryIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            {loading && <CircularProgress size="1.5rem" />}
            {!loading && item.status && (
              <Chip
                label={item.status}
                size="small"
                color={item.status === "online" ? "secondary" : ""}
              />
            )}
          </Grid>
        </Grid>
      </TableCell>

      <TableCell>
        {/* {item.deviceStatus} */}
        <Chip
          label={item.deviceStatus}
          size="small"
          color={item.deviceStatus === "ACTIVATED" ? "secondary" : ""}
        />
      </TableCell>
      <TableCell>
        {item.dataProductName}
        {item.deviceStatus === "ACTIVATED" && (
          <Tooltip title="Change Plan">
            <IconButton
              onClick={() => {
                setChangePlan(item);
              }}
              size="small"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}

        {item.status === "online" && (
          <Tooltip title="Tower Switch">
            <IconButton
              onClick={() => {
                switchTower(item);
              }}
              size="small"
            >
              <SwapHorizIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>

      <TableCell>
        {item.deviceType === "T6"
          ? "Spark"
          : item.deviceType.includes("CPE")
          ? "CPE"
          : ""}
      </TableCell>

      <TableCell>{item.deviceColor}</TableCell>
      <TableCell>
        {loading && <CircularProgress size="1.5rem" />}
        {!loading && item.plmn && item.plmn === "310410"
          ? "AT&T"
          : item.plmn === "310260"
          ? "T-Mobile"
          : item.plmn === "311480"
          ? "Verizon Wireless"
          : item.plmn}
      </TableCell>
      <TableCell>
        {item.deviceStatus === "ACTIVATED" && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              suspendDevice(item.deviceNO);
            }}
          >
            Suspend{" "}
          </Button>
        )}

        {item.deviceStatus !== "ACTIVATED" && (
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            onClick={() => {
              setActivatePlan(item);
            }}
          >
            Activate
          </Button>
        )}
      </TableCell>
      {/* </TableRow> */}
    </InView>
  );
};

export default DeviceRow;
