import React, { useState, useEffect } from "react";
import Loader from "../components/Loader";
import {
  Box,
  Container,
  Grid,
  Typography,
  Paper,
  Button,
  TextField,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import DeviceConnectionStatusDialog from "../components/DeviceConnectionStatusDialog";
import DeviceActionHistory from "../components/DeviceActionHistory";
import ChangePlan from "../components/ChangePlan";
import TableHeadCell from "../components/TableHeadCell";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeviceRow from "./../components/DeviceRow";
import Snackbar from "@material-ui/core/Snackbar";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import Pagination from "@material-ui/lab/Pagination";
import { default as tuge_api } from "../apis/tuge";

const Devices = (props) => {
  const { user } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [deviceId, setDeviceId] = useState("");
  const [deviceData, setDeviceData] = useState(null);
  const [selectedPage, setSelectedPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState("");
  const [error, setError] = useState(undefined);
  const [successMessage, setSuccessMessage] = useState(undefined);
  const [changePlan, setChangePlan] = useState(null);
  const [activatePlan, setActivatePlan] = useState(null);
  const [totalPages, setTotalPages] = useState([]);
  const [showConnectionStatusDeviceId, setShowConnectionStatusDeviceId] =
    useState(null);
  const [actionHistoryDeviceId, setActionHistoryDeviceId] = useState(null);
  const [message, setMessage] = useState(null);
  const [masterAccount, setMasterAccount] = useState(false);
  let exportCheckInterval;

  const suspendDevice = (device_id) => {
    setLoading(true);
    tuge_api
      .get(`/device/${device_id}/suspend`)
      .then(({ data }) => {
        if (data[0].resultCode === "0000") {
          setSuccessMessage(`${device_id} Device Suspended Successfully.`);
          searchDevice();
        } else {
          console.log(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const switchTower = (item) => {
    setLoading(true);
    tuge_api.get(`/device/${item.deviceNO}/switch-tower`).then(({ data }) => {
      console.log(data);
      setMessage(`Tower Switched Successfully for device ${data.deviceNo}`);
      setLoading(false);
    });
  };

  const closeConnectionStatusPopup = () => {
    setShowConnectionStatusDeviceId(null);
  };

  const closeActionHistoryPopup = () => {
    setActionHistoryDeviceId(null);
  };

  const closeChangePlanPopup = () => {
    setChangePlan(null);
  };

  const closeActivatePlanPopup = () => {
    setActivatePlan(null);
  };

  const clearSearch = () => {
    setDeviceId("");
    setStatusFilter("");
    setSelectedPage(1);
    searchDeviceWithSearchParams({});
  };

  const searchDeviceWithSearchParams = (search_params, is_master) => {
    if (user && user[window.location.origin + "/clients"]) {
      if (deviceData !== null && deviceData.last_index !== undefined) {
        search_params.start_index = deviceData.last_index;
      }
      setLoading(true);
      tuge_api
        .get("/devices", {
          params: {
            ...search_params,
            clients: user[window.location.origin + "/clients"].join(","),
            is_master: user[window.location.origin + "/is_master"],
          },
        })
        .then(({ data }) => {
          console.log(data);
          setTotalPages(Math.ceil(data.total / 50));
          setDeviceData(data);
        })
        .catch((error) => {
          console.log(error.response.data);
          setError("Error Occurred, Please retry !");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const exportFullData = () => {
    setLoading(true);
    let params = { device_status: statusFilter };
    if (!masterAccount) {
      params.client = user[window.location.origin + "/clients"].join(",");
    }
    tuge_api
      .get("/request-export-devices-connection-status", {
        params: params,
      })
      .then(({ data }) => {
        console.log(data);
        if (data.id !== undefined) {
          exportCheckInterval = setInterval(checkExportStatus, 7000, data.id);
        }
      });
  };

  const exportData = () => {
    setLoading(true);
    let params = { device_status: statusFilter };

    if (!masterAccount) {
      params.client = user[window.location.origin + "/clients"].join(",");
    }

    tuge_api
      .get("/export-devices", {
        params: params,
      })
      .then(({ data }) => {
        console.log(data);
        const url = window.URL.createObjectURL(
          new Blob([data], { type: "text/csv" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `device_export_${moment().format("YYYYMMDDhmmss")}.csv`
        );
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      });
  };

  const searchDevice = () => {
    if (deviceId.trim() === "") {
      alert("Enter Device Id");
    } else {
      setLoading(true);
      tuge_api
        .get(`/device/${deviceId}/info`)
        .then(({ data }) => {
          console.log(data);
          if (data.error) {
            setError(data.error);
          } else {
            setError(undefined);
            setDeviceData(data);
          }
        })
        .catch((error) => {
          console.log();
          setError(error.response.data.detail);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const downloadExportData = (id) => {
    setLoading(true);

    tuge_api.get(`/export/${id}/download`).then(({ data }) => {
      console.log(data);
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "text/csv" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `device_export_${moment().format("YYYYMMDDhmmss")}.csv`
      );
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    });
  };

  const checkExportStatus = (id) => {
    setLoading(true);
    tuge_api.get(`/export/${id}/status`).then(({ data }) => {
      console.log(data);
      if (data.status === "COMPLETED") {
        setLoading(false);
        clearInterval(exportCheckInterval);
        downloadExportData(id);
      } else if (data.status === "FAILED") {
        setMessage({ type: "error", text: data.error });
        setLoading(false);
        clearInterval(exportCheckInterval);
      }
    });
  };

  useEffect(() => {
    setMasterAccount(user[window.location.origin + "/is_master"]);
    searchDeviceWithSearchParams(
      {},
      user[window.location.origin + "/is_master"]
    );
    // const filterValues = queryString.parse(location.search);
    // console.log(filterValues);
    // if(filterValues.username){
    //   setUsername(filterValues.username);
    //   getUsersEmailHistory(filterValues.username);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <React.Fragment>
      {loading && <Loader />}
      <Container fixed>
        <Box mb={2} pt={2}>
          <Typography variant="h5" align="left">
            {props.title}
          </Typography>
        </Box>

        <Grid
          container
          direction="row"
          justify="flex-start"
          spacing={4}
          alignItems="center"
        >
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              id="device_id"
              label="Device ID"
              variant="outlined"
              fullWidth
              value={deviceId}
              onChange={(e) => setDeviceId(e.target.value)}
              size="small"
            />
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={searchDevice}>
              Search Device
            </Button>
          </Grid>

          <Grid item>
            <Button variant="outlined" color="primary" onClick={clearSearch}>
              Clear Search
            </Button>
          </Grid>
        </Grid>

        {error && (
          <Box pt={3}>
            <Alert severity="error"> {error} </Alert>
          </Box>
        )}

        {successMessage && (
          <Box pt={3}>
            <Alert severity="success"> {successMessage} </Alert>
          </Box>
        )}

        <Box mt={3}>
          <Box pb={2}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  spacing="2"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h7" align="center">
                      Filter By Status :
                    </Typography>
                  </Grid>
                  <Grid item>
                    <RadioGroup
                      aria-label="status"
                      name="status"
                      value={statusFilter}
                      onChange={(e) => {
                        setStatusFilter(e.target.value);
                        deviceData.last_index = 0;
                        searchDeviceWithSearchParams(
                          {
                            device_status: e.target.value,
                          },
                          masterAccount
                        );
                      }}
                      row
                    >
                      <FormControlLabel
                        value="ACTIVATED"
                        control={<Radio />}
                        label="Active"
                      />
                      <FormControlLabel
                        value="SUSPEND"
                        control={<Radio />}
                        label="Suspend"
                      />
                      <FormControlLabel
                        value="DISCONTINUEUSE"
                        control={<Radio />}
                        label="Discontinue"
                      />
                      <FormControlLabel
                        value=""
                        control={<Radio />}
                        label="All"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  spacing="2"
                  alignItems="center"
                >
                  <Pagination
                    count={totalPages}
                    shape="rounded"
                    showFirstButton
                    showLastButton
                    color="primary"
                    page={selectedPage}
                    onChange={(e, value) => {
                      setSelectedPage(value);
                      searchDeviceWithSearchParams(
                        {
                          page: value,
                          device_status: statusFilter,
                        },
                        masterAccount
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box pb={2}>
            <Grid
              container
              direction="row"
              justify="space-between"
              spacing="2"
              alignItems="center"
            >
              <Grid item>
                {" "}
                Total Devices : {deviceData && deviceData.total}{" "}
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing="2">
                  <Grid item>
                    {" "}
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<GetAppIcon />}
                      onClick={exportData}
                    >
                      {" "}
                      Export
                    </Button>{" "}
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<GetAppIcon />}
                      onClick={exportFullData}
                    >
                      Export Data with Connection Status
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {deviceData && deviceData.devices.length !== 0 && (
            <TableContainer component={Paper}>
              <Table size="small" style={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>Serial Number</TableHeadCell>
                    <TableHeadCell>Status</TableHeadCell>
                    <TableHeadCell>Plan</TableHeadCell>
                    <TableHeadCell>Type</TableHeadCell>
                    <TableHeadCell>Color</TableHeadCell>
                    <TableHeadCell>Carrier</TableHeadCell>
                    <TableHeadCell>Action</TableHeadCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {deviceData.devices.map((item) => {
                    return (
                      <DeviceRow
                        item={item}
                        suspendDevice={suspendDevice}
                        setChangePlan={setChangePlan}
                        setActivatePlan={setActivatePlan}
                        setShowConnectionStatusDeviceId={
                          setShowConnectionStatusDeviceId
                        }
                        setShowActionHistory={setActionHistoryDeviceId}
                        switchTower={switchTower}
                        key={item.deviceNO}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Container>
      <DeviceConnectionStatusDialog
        onClose={closeConnectionStatusPopup}
        open={showConnectionStatusDeviceId}
        deviceId={showConnectionStatusDeviceId}
      />

      <DeviceActionHistory
        onClose={closeActionHistoryPopup}
        open={actionHistoryDeviceId}
        deviceId={actionHistoryDeviceId}
      />
      <ChangePlan
        onClose={closeChangePlanPopup}
        open={changePlan !== null}
        deviceId={changePlan !== null && changePlan.deviceNO}
        title="Change Plan for "
        buttonTitle="Change Plan"
        selectedPlan={changePlan !== null && changePlan.dataProductName}
      />

      <ChangePlan
        onClose={closeActivatePlanPopup}
        open={activatePlan !== null}
        deviceId={activatePlan !== null && activatePlan.deviceNO}
        title="Activate Plan for "
        buttonTitle="Activate Plan"
      />
      <Snackbar
        open={message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={() => {
          setMessage(undefined);
        }}
      >
        <Alert
          onClose={() => {
            setMessage(undefined);
          }}
          severity="success"
        >
          {message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

Devices.defaultProps = {
  title: "Manage Devices",
};

export default Devices;
