import React, { useState, useEffect } from "react";
import Loader from "../components/Loader";
import Alert from "@material-ui/lab/Alert";
import {
    Tooltip,
    Box,
    Typography,
    Paper,
    Grid,
    TextField,
    Button,
    Container,
} from "@material-ui/core";
import moment from "moment";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { useAuth0 } from "@auth0/auth0-react";
import DevicesIcon from '@material-ui/icons/Devices';
import ListIcon from '@material-ui/icons/List';
import { useHistory } from "react-router-dom";
import { default as tuge_api } from "../apis/tuge";

export default function Clients() {
    let history = useHistory();
    const { user } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState();
    const [clientName, setClientName] = useState();
    const [clientsData, setClientsData] = useState([]);
    const getClients = () => {
        setLoading(true);
        tuge_api
          .get("/sub-clients", {
            params: {
              client: user[window.location.origin + "/clients"][0],
            },
          })
          .then((response) => {
            setClientsData(response.data);
            setLoading(false);
          });
    };

    const createClient = () => {
        setLoading(true);
        tuge_api
          .post(
            "/create-client",
            {
              parent_client_name: user[window.location.origin + "/clients"][0],
              new_client_name: clientName,
            }
          )
          .then((response) => {
            setMessage(response.data);
            getClients();
          }).finally(()=>{
            setLoading(false);
          })
    };

    useEffect(() => {
        getClients();
        // eslint-disable-next-line
    }, []);

    return (
      <React.Fragment>
        {loading && <Loader />}
        <Container fixed>
          <Box pt={2} pb={3}>
            <Typography variant="h3" align="left">
              Manage Clients
            </Typography>
          </Box>

          {message && (
            <Box pt={3} pb={3}>
              <Alert severity={message.type}> {message.text} </Alert>
            </Box>
          )}
          <Box pb={3}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              spacing={4}
              alignItems="center"
            >
              <Grid item xs={12} md={3} lg={3}>
                <TextField
                  id="client_name"
                  label="Client Name"
                  variant="outlined"
                  fullWidth
                  value={clientName}
                  onChange={(e) => setClientName(e.target.value)}
                  size="small"
                />
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={createClient}
                  disabled={clientName === undefined || clientName === ""}
                >
                  Create Client
                </Button>
              </Grid>
            </Grid>
          </Box>

          {clientsData.map((client) => (
            <Box pt={2}>
              <Paper elevation={3}>
                <Box p={2} key={client.id}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item lg={2} xl={2}>
                      <Typography variant="h5"> {client.name} </Typography>
                    </Grid>
                    <Grid item>
                      <Grid item container spacing={1}>
                        <Grid item>
                          <Tooltip title="Created Date & Time">
                            <AccessTimeIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Typography variant="body">
                            {moment
                              .utc(client.created_at)
                              .local()
                              .format("LLL")}{" "}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ListIcon />}
                        onClick={() => {
                          history.push(
                            `/client/${client.name}/devices/${client.id}`
                          );
                        }}
                      >
                        View Devices
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<DevicesIcon />}
                        onClick={() => {
                          history.push(
                            `/client/${client.name}/assign-devices/${client.id}`
                          );
                        }}
                      >
                        Assign Devices
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<DevicesIcon />}
                        onClick={() => {
                          history.push(
                            `/client/${client.name}/unassign-devices/${client.id}`
                          );
                        }}
                      >
                        Un-Assign Devices
                      </Button>
                    </Grid>
                    {/* <Grid item>
                      <Tooltip title="Delete Tag">
                        <IconButton
                          onClick={() => {
                            deleteTag(tag.id);
                          }}
                          size="small"
                        >
                          <DeleteIcon style={{ color: red[500] }} />
                        </IconButton>
                      </Tooltip>
                    </Grid> */}
                  </Grid>
                </Box>
              </Paper>
            </Box>
          ))}

          {clientsData.length === 0 && (
            <Box pt={2}>
              <Typography variant="h5" align="center">
                No Clients.
              </Typography>
            </Box>
          )}
        </Container>
      </React.Fragment>
    );
}
