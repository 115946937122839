import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Loader from "../Loader";
import { default as iqsim_api } from "./../../apis/iqsim";

const AssignSimCardToDevice = (props) => {
    const {sim_card_id} = props;
  const [loading, setLoading] = useState(false);
  const [deviceData,setDeviceData] = useState([]);
  const [error, setError] = useState(undefined);
  const [message, setMessage] = useState(undefined);
  const [open,setOpen] = useState(false);
  const [selectedDevice,setSelectedDevice] = useState();

const getDevices = ()=>{
setLoading(true);
iqsim_api
  .get("/devices")
  .then(({ data }) => {
    console.log(data);
    let devices = [];
    for (const device of data.listDevice) {
      if (device.portgroups === "CPE Tmobile") {
        devices.push(device);
      }
    }
    setDeviceData(devices);
  })
  .catch((error) => {
    // console.log(error.response.data);
    setError("Error Occurred, Please retry !");
  })
  .finally(() => {
    setLoading(false);
  });
}


  const assignSimCardToDevice = () => {
    let data = { sim_card_id, device_name: selectedDevice };
    console.log(data);
    setLoading(true);
    iqsim_api
      .post(
        `/assign-sim-to-device`,
        data
      )
      .then(({ data }) => {
        console.log(data);
        if (data.success) {
          setMessage("New Sim Assigned Successfully.");
        }
      })
      .catch((error) => {
        // console.log(error.response.data);
        setError("Error Occurred, Please retry !");
      })
      .finally(() => {
        setLoading(false);
      });
  };

useEffect(() => {
  if (open) {
    getDevices();
  }
}, [sim_card_id, open]);

  return (
    <React.Fragment>
      <Box p={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => {
            setOpen(true);
          }}
        >
          Assign Device
        </Button>
      </Box>

      <Dialog
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        aria-labelledby="simple-dialog-title"
        fullWidth={false}
        maxWidth={"lg"}
      >
        <DialogTitle id="simple-dialog-title">
          Assign Sim for {sim_card_id}
        </DialogTitle>
        <DialogContent>
          {loading && <Loader />}
          {error && (
            <Box pt={3}>
              <Alert severity="error"> {error} </Alert>
            </Box>
          )}

          {message && (
            <Box pt={3}>
              <Alert severity="success"> {message} </Alert>
            </Box>
          )}
          {deviceData && (
            <Box pt={3} pb={3}>
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
                spacing={3}
              >
                <Grid item>
                  <FormControl style={{ minWidth: 150 }}>
                    <InputLabel id="select-plan">Select Device</InputLabel>
                    <Select
                      labelId="select-plan"
                      id="plan"
                      value={selectedDevice}
                      onChange={(e) => setSelectedDevice(e.target.value)}
                      fullWidth
                    >
                      {deviceData.map((plan, index) => (
                        <MenuItem value={plan.name} key={index}>
                          {plan.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    component={Link}
                    onClick={() => {
                      assignSimCardToDevice();
                    }}
                    disabled={selectedDevice === null || selectedDevice===undefined}
                  >
                    Assign Device
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default AssignSimCardToDevice;
