import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PersonAddIcon from '@material-ui/icons/PersonAdd';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
  },
  logo: {
    flexGrow: "1",
  },
  reportBtn: {
    textTransform: "none",
  },
}));
const Navigation = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  console.log(isAuthenticated);
  const classes = useStyles();
  const { user } = useAuth0();


  return (
    <AppBar position="static" className={classes.root}>
      <Box p={1}>
        <Toolbar>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Box className={classes.logo}>
                <Typography variant="h5" color="primary">
                  Device Manager
                </Typography>
              </Box>
            </Grid>

            <Grid item>
              <Grid container direction="row" spacing={3}>
                {isAuthenticated && (
                  <React.Fragment>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.reportBtn}
                        startIcon={<ListIcon />}
                        component={Link}
                        to="/devices"
                      >
                        Devices
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.reportBtn}
                        startIcon={<PersonAddIcon />}
                        component={Link}
                        to="/clients"
                      >
                        Clients
                      </Button>
                    </Grid>

                    {user[window.location.origin + "/is_master"] && (
                      <React.Fragment>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.reportBtn}
                            startIcon={<AssessmentIcon />}
                            component={Link}
                            to="/report"
                          >
                            Report
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.reportBtn}
                            component={Link}
                            to="/vsim-devices"
                          >
                            vSIM Devices
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.reportBtn}
                            component={Link}
                            to="/vsim-cards"
                          >
                            vSIM Cards
                          </Button>
                        </Grid>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}

                <Grid item>
                  {!isAuthenticated ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.reportBtn}
                      startIcon={<PermIdentityIcon />}
                      onClick={() =>
                        loginWithRedirect({
                          redirectUri: `${window.location.origin}/devices`,
                        })
                      }
                    >
                      Login
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.reportBtn}
                      startIcon={<ExitToAppIcon />}
                      onClick={() =>
                        logout({
                          returnTo: window.location.origin,
                        })
                      }
                    >
                      Logout
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default Navigation;
