import React from "react";
import "./App.css";
// import Header from "./components/Header";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles"; 
import { grey } from "@material-ui/core/colors";
import Devices from "./layouts/Devices";
import Profile from "./components/profile";
import Navigation from "./components/Navigation";
import Loader from "./components/Loader";
import { useAuth0 } from "@auth0/auth0-react";
import ProtectedRoute from './components/protected-route';
import Report from "./layouts/Report";
import Clients from "./components/Clients";
import ClientDevices from "./components/ClientDevices";
import AssignDevices from "./components/AssignDevices";
import IQSimDevices from "./layouts/IQSimDevices";
import IQSimCards from "./layouts/IQSimCards";
import UnAssignDevices from "./components/UnAssignDevices";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1D4354",
    },
    secondary: {
      main: "#6FDA44",
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  colors: {
    green: "#6FDA44",
    darkBlue: "#1D4354",
    grey: "#f2f2f2",
  },
});

function App() {
  const { isLoading } = useAuth0();
  
  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <Navigation />

          <div className="mainPanel" style={{ backgroundColor: grey[50] }}>
            <ProtectedRoute path="/devices" component={Devices} />
            <ProtectedRoute path="/clients" component={Clients} />
            <ProtectedRoute path="/profile" component={Profile} />
            <ProtectedRoute path="/report" component={Report} />
            <ProtectedRoute path="/vsim-devices" component={IQSimDevices} />
            <ProtectedRoute path="/vsim-cards" component={IQSimCards} />
            <ProtectedRoute
              path="/client/:client_name/devices/:client_id"
              component={ClientDevices}
            />

            <ProtectedRoute
              path="/client/:client_name/assign-devices/:client_id"
              component={AssignDevices}
            />
            <ProtectedRoute
              path="/client/:client_name/unassign-devices/:client_id"
              component={UnAssignDevices}
            />
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
